import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import industry from "../images/3.0 Investment Criteria - Growth Partners/Industry.svg";
import investment from "../images/3.0 Investment Criteria - Growth Partners/Investment.svg";
import location from "../images/3.0 Investment Criteria - Growth Partners/Location.svg";
import netIncome from "../images/3.0 Investment Criteria - Growth Partners/Net Income.svg";
import typeOfFinancing from "../images/3.0 Investment Criteria - Growth Partners/Type of Financing.svg";

const Page = () => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <Layout>
      <SearchEngineOptimization
        title="Assurance Growth Partners | Private Equity Fund"
        description="Accelerate your company's success with an investment from Assurance Growth Partners. We offer up to $20M in solutions tailored to your business. Contact us."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden pt-14 pb-20 md:pt-22 md:pb-52">
        <div className="absolute top-0 left-0 h-full w-full">
          <StaticImage
            src="../images/3.0 Investment Criteria - Growth Partners/1.0 hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container relative">
          <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="text-center md:text-left">
              <h1 className="text-white">Assurance Growth Partners</h1>
              <p className="text-white/80 md:text-xl">
                Assurance Growth Partners looks to work with business owners to allow them to fully exit or partially exit via control equity investments in private, lower middle market U.S.-based companies.
              </p>
              <ButtonSolid modal="modal-contact" text="Contact" />
            </div>
            <div>
              <StaticImage
                src="../images/3.0 Investment Criteria - Growth Partners/1.1 hero.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="relative -mx-4 bg-primary-50 px-2 pb-20 md:mx-0 md:-mt-26 md:mb-32 md:px-14 md:pt-26 md:pb-32 lg:px-26">
          <div className="container">
            <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
              <ScrollNavigation>
                <li>
                  <AnchorLink
                    to={url + "#section-1"}
                    title="Criteria"
                    stripHash
                  />
                </li>
                <li>
                  <AnchorLink
                    to={url + "#section-2"}
                    title="Structure"
                    stripHash
                  />
                </li>
              </ScrollNavigation>

              <div className="space-y-10 md:col-span-9 md:col-end-13 md:space-y-24">
                <div id="section-1" className="bg-white p-8 shadow-5xl md:p-14">
                  <header className="mb-10 md:mb-14">
                    <h2>Our Investment Criteria</h2>
                    <p className="mb-0">
                      We’re seeking experienced, committed, and personally invested management teams within companies that have effective financial controls with a history of profitability.
                    </p>
                  </header>

                  <div className="space-y-10">
                    <div>
                      <img src={location} className="mb-2.5" />
                      <h3 className="heading-six">Location</h3>
                      <p className="mb-0">
                        We’re looking to invest with private and public companies generally headquartered within the United States.
                      </p>
                    </div>

                    <div>
                      <img src={netIncome} className="mb-2.5" />
                      <h3 className="heading-six">Net Income</h3>
                      <p className="mb-0">
                        Your earnings before interest, taxes, depreciation, and amortization (EBITDA) should be between $2 and $10 million.
                      </p>
                    </div>

                    <div>
                      <img src={industry} className="mb-2.5" />
                      <h3 className="heading-six">Industry</h3>
                      <p className="mb-0">
                        We invest in a variety of industries, with the exception of real estate or oil and gas exploration (however, oil and gas service companies are welcome).
                      </p>
                    </div>
                  </div>
                </div>

                <div id="section-2" className="bg-white p-8 shadow-5xl md:p-14">
                  <header className="mb-10 md:mb-14">
                    <h2>Financing Structure</h2>
                    <p className="mb-0">
                    Our goal is to partner with business owners and supply them with the capital and expertise to allow them to achieve their growth ambitions while also giving them an opportunity to take some chips off the table.
                    </p>
                  </header>
                  
                  <div className="space-y-10">
                    <div>
                      <img src={investment} className="mb-2.5" />
                      <h3 className="heading-six">Enterprise Value</h3>
                      <p className="mb-0">
                        We are seeking to invest in companies with an enterprise value between $5 million and $75 million
                      </p>
                    </div>

                    <div>
                      <img src={typeOfFinancing} className="mb-2.5" />
                      <h3 className="heading-six">Type of Financing</h3>
                      <p className="mb-0">
                        Control equity investments with the opportunity for sellers to retain equity, allowing sellers to participate in equity appreciation
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
